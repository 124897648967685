header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 10px;
}

main {
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  font-size: 10px;
}
footer a {
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 1px solid #3689ff;
  color: #3689ff;
}

html,
body {
  border: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

* {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", -apple-system-headline, Helvetica, Arial, san-serif;
}

#modeBtn {
  padding: 0 15px;
  height: 50px;
  border-radius: 25px;
  background-color: #1dc100;
  color: white;
  font-weight: 800;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

h1 {
  color: #4d87ff;
  font-size: 22px;
  font-weight: 400;
}

/** stolen from https://fossheim.io/writing/posts/css-text-gradient/ **/
textarea.main {
  /* font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B",
    -apple-system-headline, Helvetica, Arial, san-serif; */
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: none;
  text-align: center;
  font-size: 14vw;
  font-weight: 800;
  text-transform: uppercase;
  /* -webkit-background-clip: text;
  background-clip: text; */
  width: 100%;
  min-height: 50px;
  max-height: 250px;
  padding: 0;
  margin: 0;
  resize: none;

  /** background-image: linear-gradient(#ca4246 20%, #f18f43 80%); **/
  color: #f30069;
  /* -webkit-text-fill-color: transparent; */
  white-space: nowrap;
}

p {
  margin-top: 20px;
}

.green {
  color: #00c500;
}

html,
body,
#root {
  background-color: white;
}
